import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import sessionInfo from "../app/views/sessions/SessionSlice";

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		sessionInfo
	},
});
