import { getSessionUserInformation } from "app/utils/utility.service";
import { Navigate } from "react-router-dom";
import { DefaultLayout } from "../components";
import sessionRoutes from "../views/sessions/SessionRoutes";
import advertisementRoutes from "app/views/advertisements/AdvertisementRoutes";

export const AllPages = () => {
    const sessionValues = getSessionUserInformation();
    const all_routes = [
        {
            element: <DefaultLayout />,
            children: [...sessionRoutes, ...advertisementRoutes],
        },
    ];
    all_routes.push({
        path: "/",
        element: <Navigate to="/advertisements" />,
    });
    return all_routes;
};
