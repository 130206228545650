import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();

export const getApplicationList = async (id, payload) => {
    const result = await restClient.get(`${BASE_URL}/applications?user_id=${id}`);
    return result?.data; 
}

export const createApplication = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/applications`, payload);
    return result; 
    // return true;
}

export const updateApplication = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/applications/${id}`, payload);
    return result?.data; 
}

export const getSingleApplication = async (id) => {
    const result = await restClient.get(`${BASE_URL}/applications/${id}`);
    return result?.data; 
}

export const deleteApplication = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/applications/${id}`);
    return result?.data; 
}
