import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();


export const updateUser = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/profile/${id}`, payload);
    return result; 
}

export const getSingleUser = async (id) => {
    const result = await restClient.get(`${BASE_URL}/profile/${id}`);
    return result?.data; 
}
