import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();

export const getExperienceList = async (id, payload) => {
    const result = await restClient.get(`${BASE_URL}/experiences?user_id=${id}`);
    return result?.data; 
}

export const createExperience = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/experiences`, payload);
    return result?.data; 
    // return true;
}

export const updateExperience = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/experiences/${id}`, payload);
    return result?.data; 
}

export const getSingleExperience = async (id) => {
    const result = await restClient.get(`${BASE_URL}/experiences/${id}`);
    return result?.data; 
}

export const deleteExperience = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/experiences/${id}`);
    return result?.data; 
}
